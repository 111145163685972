import { createAsyncThunk } from '@reduxjs/toolkit';
import { serialize } from 'object-to-formdata';
import queryString from 'query-string';
import apiKey from '../../../utils/axiosInstance';
export const getProtheses = createAsyncThunk('prothese/getProtheses', async ({ page, limit, status }, ThunkApi) => {
    try {
        const query = queryString.stringify({ limit, page, status });
        const response = await apiKey.get(`/protheses?${query}`);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const getRealisedProtheses = createAsyncThunk('prothese/getRealisedProtheses', async ({ laboratoryID }, ThunkApi) => {
    try {
        const query = queryString.stringify({ laboratoryID });
        const response = await apiKey.get(`/protheses/realised-protheses?${query}`);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const getRequestedProtheses = createAsyncThunk('prothese/getRequestedProtheses', async ({ cabinetID }, ThunkApi) => {
    try {
        const query = queryString.stringify({ cabinetID });
        const response = await apiKey.get(`/protheses/requested-protheses?${query}`);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const getSearchedProtheses = createAsyncThunk('prothese/getSearchedProtheses', async ({ page, limit, search }, ThunkApi) => {
    try {
        const query = queryString.stringify(!search ? { limit, page } : { limit, page, search });
        const response = await apiKey.get(`/protheses/draft?${query}`);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const createTraitements = createAsyncThunk('prothese/createProtheses', async ({ params }, ThunkApi) => {
    try {
        const response = await apiKey.post(`/traitements`, params);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const updateTraitements = createAsyncThunk('prothese/updateTraitements', async ({ id, params }, ThunkApi) => {
    try {
        const response = await apiKey.put(`/traitements/${id}`, params);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const createProthese = createAsyncThunk('prothese/createProthese', async ({ params }, ThunkApi) => {
    try {
        const response = await apiKey.post(`/protheses`, serialize({ ...params }));
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const updateProthese = createAsyncThunk('prothese/updateProthese', async ({ id, params }, ThunkApi) => {
    try {
        const response = await apiKey.put(`/protheses/${id}`, serialize({ ...params }));
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const getProtheseById = createAsyncThunk('prothese/getProtheseById', async ({ id }, ThunkApi) => {
    try {
        const response = await apiKey.get(`/protheses/${id}`);
        return response;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const deleteProthese = createAsyncThunk('prothese/deleteProthese', async ({ id }, ThunkApi) => {
    try {
        const response = await apiKey.delete(`/protheses/${id}`);
        return response;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
